import styles from '../../styles/components/blocks/newsletter.module.scss';
import type { Attributes } from '../../types/blocks/attributes';
import { newsletterApi } from '../../lib/apis/newsletter';
import { useState } from 'react';
import classNames from 'classnames';

interface Props {
  title?: string;
  content?: string;
  attributes?: Attributes;
  noTopPadding?: boolean;
}

export default function Newsletter({ title, content, attributes, noTopPadding }: Props): JSX.Element {
  const [displayError, setDisplayError] = useState(false);
  const [displaySuccess, setDisplaySuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  async function signupUser(event: any) {
    setDisplayError(false);
    setDisplaySuccess(false);
    event.preventDefault();

    const response = await newsletterApi(event.target);
    if (!response.Code) {
      setErrorMessage('Signed up successfully');
      setDisplaySuccess(true);
    } else {
      setDisplayError(true);
      setErrorMessage(response.Message);
    }
  }

  return (
    <div
      id={attributes?.anchor}
      className={classNames(styles.newsletter__wrapper, {
        [styles.no_top_padding]: noTopPadding,
      })}
    >
      <div className="container">
        <div className={styles.newsletter__inner_wrapper}>
          <div className={styles.newsletter__col_left}>
            <h3>{title}</h3>
            <div className={styles.newsletter__content} dangerouslySetInnerHTML={{ __html: content && content.length > 0 ? content : '' }} />
          </div>

          <div className={styles.newsletter__col_right}>
            <form
              onSubmit={signupUser}
              className={classNames(styles.newsletter__form, {
                [styles.show_success]: displaySuccess,
              })}
            >
              <div className={classNames(styles.newsletter__col_right_row, styles.newsletter__name_email_wrapper)}>
                <div className={styles.newsletter__name}>
                  <label htmlFor="name">Name</label>
                  <input id="name" type="text" autoComplete="name" required />
                </div>

                <div className={`${styles.newsletter__email}`}>
                  <label htmlFor="name">Email</label>
                  <input id="email" type="email" autoComplete="email" required />
                </div>
              </div>

              <div className={classNames(styles.newsletter__col_right_row, styles.newsletter__country_of_interest_wrapper)}>
                <div className={styles.newsletter__country_of_interest}>
                  <label htmlFor="name">Country of interest</label>
                </div>

                <div className={styles.newsletter__checkbox_wrapper}>
                  <label>
                    <input id="aus" type="checkbox" />
                    Australia
                  </label>
                  <label>
                    <input id="nz" type="checkbox" />
                    New Zealand
                  </label>
                  <label>
                    <input id="uk" type="checkbox" />
                    United Kingdom
                  </label>
                  <label>
                    <input id="usa" type="checkbox" />
                    United States of America
                  </label>
                  <label>
                    <input id="indo" type="checkbox" />
                    Indonesia
                  </label>
                  <label>
                    <input id="int" type="checkbox" />
                    International
                  </label>
                  <label>
                    <input id="asia" type="checkbox" />
                    Asia-Pacific
                  </label>
                </div>
              </div>

              <div className={classNames(styles.newsletter__col_right_row, styles.newsletter__subscribe_to_wrapper)}>
                <div className={styles.newsletter__subscribe_to}>
                  <label htmlFor="name">Subscribe To</label>
                </div>

                <div className={styles.newsletter__checkbox_wrapper}>
                  <label>
                    <input id="morgan" type="checkbox" />
                    Morgan Poll Update
                  </label>
                  <label>
                    <input id="mru" type="checkbox" />
                    Market Research Update
                  </label>
                  <label>
                    <input id="abix" type="checkbox" />
                    ABIX News
                  </label>
                </div>
              </div>
              <button className="btn primary" type="submit">
                Subscribe
              </button>
            </form>

            {displayError && <div className={styles.newsletter_error_message}>{errorMessage}</div>}
            {displaySuccess && <div className={styles.newsletter_success_message}>{errorMessage}</div>}
          </div>
        </div>
      </div>
    </div>
  );
}
