import styles from '../../styles/components/blocks/testimonial.module.scss';
import type { Image as ImageType } from '../../types/blocks/image';
import type { Attributes } from '../../types/blocks/attributes';
import quoteIcon from '../../public/images/icons/quote.png';
import CickThroughLink from '../click-through-link';

interface Props {
  image?: ImageType;
  quote: string;
  quote_source?: string;
  quote_description?: string;
  click_through_link?: {
      title: string
      url: string
  };
  full_width?: boolean;
  attributes?: Attributes;
}

export default function Testimonial({
  image,
  quote,
  quote_source,
  quote_description,
  click_through_link,
  full_width,
  attributes
}: Props): JSX.Element {

  return (
    <div id={attributes?.anchor} className="container">
      <div className={styles.testimonial__wrapper}>
          <img 
            src={quoteIcon.src} 
            alt="Quote Icon" 
            className={`
              ${styles.testimonial__quote_icon}
              ${image ? styles.has_image : ''}
            `} 
          />
          { image && (
            <img src={image?.sourceUrl} alt="Quote Source" />
          )}
          <div 
            className={`
              ${styles.testimonial__quote} 
              ${full_width ? styles.full_width : ''}
            `}
          >
            {quote}
          </div>
          { quote_source && (
            <div className={styles.testimonial__quote_source}>
              {`— ${quote_source}`}
            </div>
          )}
          { quote_description && (
            <div className={styles.testimonial__quote_description}>
              {quote_description}
            </div>
          )}
          { click_through_link && (
            <CickThroughLink 
              text={click_through_link.title} 
              link={click_through_link.url} 
            />
          )}

      </div>
    </div>      
  );
}
