import Image from 'next/image';
import Link from 'next/link';
import arrowRight from '../public/images/arrow-right-brand.svg';
import styles from '../styles/components/click-through-link.module.scss';
import classNames from 'classnames';

interface Props extends Omit<React.ComponentProps<typeof Link>, 'href'> {
  text: string;
  link: string;
  isButton?: boolean;
}

export default function ClickThroughLink({ text, link, isButton = false, ...linkProps }: Props): JSX.Element {
  return (
    <Link href={link} {...linkProps}>
      <div className={classNames(styles.click_through_link, {
        btn: isButton,
      })}>
        {text}
        <Image
          src={arrowRight.src}
          alt={text}
          height={arrowRight.height}
          width={arrowRight.width}
        />
      </div>
    </Link>
  );
}
