import type { NewsletterFormProperties } from '../../types/apis/newsletter';

export async function newsletterApi(form: NewsletterFormProperties) {
  let name = form.name.value;
  let email = form.email.value;
  let aus = form.aus && form.aus.checked;
  let nz = form.nz && form.nz.checked;
  let uk = form.uk && form.uk.checked;
  let usa = form.usa && form.usa.checked;
  let indo = form.indo && form.indo.checked;
  let int = form.int && form.int.checked;
  let asia = form.asia && form.asia.checked;
  let morgan = form.morgan && form.morgan.checked;
  let mru = form.mru && form.mru.checked;
  let abix = form.abix && form.abix.checked;
  var customFields =[];

  if (aus){
    customFields.push({"Key": "Countryofinterest","Value": "Australia"});
  }
  if (nz){
    customFields.push({"Key": "Countryofinterest","Value": "New Zealand"});
  }
  if (uk){
    customFields.push({"Key": "Countryofinterest","Value": "United Kingdom"});
  }
  if (usa){
    customFields.push({"Key": "Countryofinterest","Value": "United States of America"});
  }
  if (indo){
    customFields.push({"Key": "Countryofinterest","Value": "Indonesia"});
  }
  if (int){
    customFields.push({"Key": "Countryofinterest","Value": "International"});
  }
  if (asia){
    customFields.push({"Key": "Countryofinterest","Value": "Asia-Pacific"});
  }
  if (morgan){
    customFields.push({"Key": "Newsletter","Value": "Morgan Poll Update"});
  }
  if (mru){
    customFields.push({"Key": "Newsletter","Value": "Market Research Update"});
  }
  const data = {
    "Name": name,
    "EmailAddress": email,
    "CustomFields": customFields,
    "Resubscribe": true,
    "RestartSubscriptionBasedAutoresponders": true,
    "ConsentToTrack":"Yes"
  };
  const headers = { 'Content-Type': 'application/json' };

  const res = await fetch(`/api/newsletter`, {
    method: 'POST',
    headers,
    body: JSON.stringify(data),
  });

  var json = await res.json();

  if (!json.Code && abix){
    const abixData = {
      "Name": name,
      "EmailAddress": email,
      "Resubscribe": true,
      "RestartSubscriptionBasedAutoresponders": true,
      "ConsentToTrack":"Yes"
    };
    const abixres = await fetch(`/api/abixnewsletter`, {
      method: 'POST',
      headers,
      body: JSON.stringify(abixData),
    });
    json = await abixres.json();
  }
  return json;
}
